import React from 'react';
import LoginElements from './elements/LoginElements';
import RegisterElements from './elements/RegisterElements';
import BottomNavBar from './BottomNavBar';

export default function(props){
  const switcher = props.userPack.loginModal.login
    return(
      <>
              <div className="alignCenter justify" style={{width:"100vw",fontSize:"28px !important",position:"relative",top:"3rem"}}>
          <p className="mobileTitle centerY">{!switcher?"Inicia sesión":"Regístrate"}</p>
          </div>
      <div className="alignCenter justify" style={{height:"85vh"}}>
  
        
      <div className="centerCard2 alignCenter justify">
        {!switcher? <LoginElements userPack={props.userPack}/>:<RegisterElements userPack={props.userPack}/>}
       
           </div>
      </div>
         <BottomNavBar userPack={props.userPack}/>
      </>
    )
  }