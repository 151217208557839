import React from 'react';
import {Link} from '@reach/router'
import MainNavBar from './elements/MainNavBar';

export default function(props){
    const switchrent=(e)=>{ 
        console.log("true")
        props.userPack.switchRent("",true)
        props.userPack.methods.neoSelected("search")
    }
    const switchsale=(e)=>{ 
        props.userPack.switchRent("",false)
        props.userPack.methods.neoSelected("search")
    }
    const publish=(e)=>{ 
        let newModal ={...props.userPack.loginModal}
        newModal.active=true
        newModal.type="publish"
        props.userPack.switchLoginModal(newModal)
    }
    return(
        <>
        <MainNavBar userPack={props.userPack}/>
         <div className="background">
    
      <div className="innerBg">
          <div ><h1 className="mainTitle">Encuentra la casa de tus sueños</h1></div>
          <div className="hugeBox">
  
              <div className="hugeSearch">
                  <div className="box1">
                      <input placeholder="Ingrese una dirección, sector o ciudad" className="mainInput" type="text" name="hugeInput" id="hugeInput"/>
                  </div>
                  <div className="box2">
                      <Link to="listings" style={{color:"inherit"}}>
                      <svg style={{position:"relative",right:"25px"}} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.364 20.636C22.7395 20.0378 22.1317 19.4225 21.5413 18.7907C21.0453 18.2867 20.7467 17.92 20.7467 17.92L17.0133 16.1373C18.508 14.4422 19.3329 12.26 19.3333 9.99999C19.3333 4.85466 15.1467 0.666656 10 0.666656C4.85333 0.666656 0.666664 4.85466 0.666664 9.99999C0.666664 15.1453 4.85333 19.3333 10 19.3333C12.3507 19.3333 14.4933 18.4533 16.1373 17.0147L17.92 20.748C17.92 20.748 18.2867 21.0467 18.7907 21.5427C19.3067 22.0267 19.9853 22.6813 20.636 23.3653L22.4467 25.2213L23.252 26.0827L26.08 23.2547L25.2187 22.4493C24.7133 21.9533 24.0387 21.2947 23.364 20.636ZM10 16.6667C6.324 16.6667 3.33333 13.676 3.33333 9.99999C3.33333 6.32399 6.324 3.33332 10 3.33332C13.676 3.33332 16.6667 6.32399 16.6667 9.99999C16.6667 13.676 13.676 16.6667 10 16.6667Z" fill="#37A000"/>
                          </svg>
                      </Link>
                  </div>
              </div>
          <div className="suggestSearch">
          </div>
                 
  
          </div>
  </div>
  </div>
  <div className="mobileBackground">
      <div className="mobileLogo" >
        <div className="">
    
        </div>
                <div className="middleLogo">
            <img className="" src="media/mainLogo.svg" alt=""/>
    
        </div>
      </div>
  <div className="" style={{paddingBottom:'5rem'}}>
    <div className="alignCenter justify" style={{display:"grid",gridTemplateColumns:"1fr",gridTemplateRows:"auto auto"}}><h1 className="mainTitleMobile" style={{textAlign:"center"}}>Encuentra la casa de tus sueños</h1></div>
          
          <div className="hugeBox alignCenter justify" style={{paddingTop:'2.5rem'}}>
  
              <div className="hugeSearchMobile">
                  <div className="box1">
                      <input placeholder="Ingrese una dirección, sector o ciudad" className="mainInput" type="text" name="hugeInput2" id="hugeInput2"/>
                  </div>
                  <div className="box2">
                      <Link to="listings" style={{color:"inherit"}}>
                      <svg style={{position:"relative",right:"25px"}} width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.364 20.636C22.7395 20.0378 22.1317 19.4225 21.5413 18.7907C21.0453 18.2867 20.7467 17.92 20.7467 17.92L17.0133 16.1373C18.508 14.4422 19.3329 12.26 19.3333 9.99999C19.3333 4.85466 15.1467 0.666656 10 0.666656C4.85333 0.666656 0.666664 4.85466 0.666664 9.99999C0.666664 15.1453 4.85333 19.3333 10 19.3333C12.3507 19.3333 14.4933 18.4533 16.1373 17.0147L17.92 20.748C17.92 20.748 18.2867 21.0467 18.7907 21.5427C19.3067 22.0267 19.9853 22.6813 20.636 23.3653L22.4467 25.2213L23.252 26.0827L26.08 23.2547L25.2187 22.4493C24.7133 21.9533 24.0387 21.2947 23.364 20.636ZM10 16.6667C6.324 16.6667 3.33333 13.676 3.33333 9.99999C3.33333 6.32399 6.324 3.33332 10 3.33332C13.676 3.33332 16.6667 6.32399 16.6667 9.99999C16.6667 13.676 13.676 16.6667 10 16.6667Z" fill="#37A000"/>
                          </svg>
                      </Link>
                  </div>
              </div>

                 
  
          </div>
  </div>
  </div>
 
  <div className="alignCenter justify" style={{paddingTop:"40px",paddingBottom:"40px"}}><p className="semiTitle" style={{color:"white"}}>Tienes preguntas acerca del COVID-19?</p></div>
  <div className="mainCards alignCenter justify" style={{backgroundColor:"white",position:"relative",bottom:"30px"}}> 
 
  <Link to="/listings" onClick={switchsale}>
  <div className="mainCard alignCenter justify">
  <img className="mainCardSvg" style={{width:"180px"}} src="media/purchaseicon2.png" alt="" srcSet=""/>
  <p className="mainCardTitle">Comprar</p>
  <span className="mainInfo">Descubre las mejores ofertas en tu zona, explora el mercado digital más completo del país</span>
  {/* <a href="#" classNa.me="mainButton alignCenter justify"><p className="mainButtonLetter">Leer más</p></a> */}
  </div>
  </Link>
 <Link to="/listings" onClick={switchrent}>
  <div className="mainCard alignCenter justify">
  <img  style={{width:"180px"}} className="mainCardSvg" src="media/rentingicon2.png" alt="" srcSet=""/>

  <p className="mainCardTitle">Alquilar</p>
  <span className="mainInfo">Tenemos el catálogo de propiedades más amplio del país gracias a nuestros análisis de mercado con inteligencia artificial</span>
  {/* <a href="#" className="mainButton alignCenter justify"><p className="mainButtonLetter">Leer más</p></a> */}
  </div>
  </Link>
  <div className="mainCard alignCenter justify buttonClass" onClick={publish}>
  <img style={{width:"180px"}} className="mainCardSvg" src="media/moneybag2.png" alt="" srcSet=""/>

  <p className="mainCardTitle">Publicar propiedad</p>
  <span className="mainInfo">Aumenta exponencialmente la exposición de tu propiedad y alcanza miles de compradores alrededor del mundo</span>
  {/* <a href="#" className="mainButton alignCenter justify"><p className="mainButtonLetter">Leer más</p></a> */}
  </div>
  </div>

  
        </>
    )
}