import React from 'react';
import {Link} from '@reach/router'
import ProfileButton from './ProfileButton';

export default function(props){
    const openLogin=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=true
        props.userPack.switchLoginModal(newVal)
      }
      const publish=(e)=>{ 
        let newModal ={...props.userPack.loginModal}
        newModal.active=true
        newModal.type="publish"
        props.userPack.switchLoginModal(newModal)
    }
      const openRegister=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=false
        props.userPack.switchLoginModal(newVal)
      }
      const neoSelected=(e)=>{
        props.userPack.methods.neoSelected(e.target.parentElement.type)
      }
      const switchrent=(e)=>{ 
        console.log("true")
        props.userPack.switchRent("",true)
        props.userPack.methods.neoSelected("search")
    }
    const switchsale=(e)=>{ 
        props.userPack.switchRent("",false)
        props.userPack.methods.neoSelected("search")
    }
    return(
        <>
         {/* <div className="mobileNav">
                    
                    <div className="alertBg">
                        <p className="normal" style={{marginTop:"10px"}}>Obtén la información más reciente y relevante acerca del COVID-19. <a href="#" className="underLine" style={{color:"inherit"}}>Leer más</a></p></div>
                    <div className="mobileSearchBar">
                        <div className="innerMobileSearch">
                            <Link to="listings" style={{color:"inherit"}} onClick={neoSelected} type="search">
                        <svg style={{marginLeft:"18px"}} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7" stroke="#37A000" strokeWidth="2"/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke="#37A000" strokeWidth="2"/>
                            </svg>
                        </Link>
                            <input className="mainInput" type="text" name="" id=""/>
                    
                            
                        </div>
                    </div>
                </div> */}
                    <div className="mobileSearchBar">
                        <div className="innerMobileSearch">
                            <Link to="listings" style={{color:"inherit"}} onClick={neoSelected} type="search">
                        <svg style={{marginLeft:"18px"}} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7" stroke="#37A000" strokeWidth="2"/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke="#37A000" strokeWidth="2"/>
                            </svg>
                        </Link>
                            <input className="mainInput" type="text" name="" id=""/>
                    
                            
                        </div>
                    </div>
                  <div className="indexNavBar">
          <div className="leftWing">
              
              <Link style={{color:"inherit"}} to="listings" onClick={switchsale}>
              <p className="navBarTitle">Comprar</p>
              </Link>
              <Link style={{color:"inherit"}} to="listings" onClick={switchrent}>
              <p className="navBarTitle">Alquilar</p>
              </Link>
  
              <a style={{color:"inherit"}} href="#">
                  <p className="navBarTitle">Préstamos</p>
                  </a>
          </div>
      <div className="middleLogo">
          <img className="" src="media/mainLogo.svg" alt=""/>
  
      </div>
          
      <div className="rightWing">
          <a style={{color:"inherit"}} href="#" onClick={publish}>
          <p className="navBarTitle">Publicar</p>
          </a>
          <a style={{color:"inherit"}} href="#">
          <p className="navBarTitle">Ayuda</p>
          </a>
          {props.userPack.user?
          <div className="alignCenter"><ProfileButton methods={props.userPack.methods} user={props.userPack.user}/></div>
          :
          <>
                    <a onClick={openLogin} style={{color:"inherit"}} href="#">
                    <p className="navBarTitle">Login</p>
                    </a>
                    <a href="#" onClick={openRegister} style={{color:"inherit"}} className="registerButton alignCenter justify">
                        <p className="registerText " >Regístrate</p>
                    </a>
                    </>
          }

      </div>  
          
      </div>
  </>
    )
}