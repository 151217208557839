import React, { Component } from 'react';
import './App.css';
import { Router, Link } from "@reach/router";
import MainView from "./MainView"
import Listings from "./Listings"
import LoginMobile from "./LoginMobile"
import LoginModal from "./elements/LoginModal"
import moment from 'moment';
import BottomNavBar from './BottomNavBar'
import Profile from './Profile';
import Chat from './elements/Chat';

const getUrl = window.location;
let host
if(getUrl.host.includes(":")){
  host = getUrl.host.substring(0, getUrl.host.length - 5);
}else{host = getUrl.host;}
let baseUrl = getUrl.protocol+ "//" + host +":8181/";
let baseWss = getUrl.protocol+ "//" + host +":8282/";

if(host.includes('mudate')){
  baseUrl="https://mudate.herokuapp.com/"
  baseWss="https://mudate-asgi.herokuapp.com/"
}
const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

const currencySymbols = [
  {
    value: 'USD',
    label: 'US$',
  },
  {
    value: 'DOP',
    label: 'RD$',
  },
  {
    value: 'EUR',
    label: '€',
  },

];

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
function validateEmail(email) {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

class App extends Component {
  state = { loginModal:{active:false,firstName:'',social:false,lastName:'',phoneNumber:'',login:null,
  username:'',password:'',innerLoading:false,innerError:null,type:""},
  selected:null,currency:{symbols:currencySymbols,
    rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269},selected:"DOP"},
          axiosPack:{axios,baseUrl,baseWss},rawResults:[],
            user:null,rent:false,dimensions:{isMobile:null,width:null,height:null,scrollValue:0}
   }  
   neoSelected=(element)=>{
     this.setState({selected:element})
   }
   processKey=(e)=>{
     if(e.code==="Escape" && this.state.loginModal.active){
        let newVal = {...this.state.loginModal}
        newVal.active=false
        this.setState({loginModal:newVal})
     }
     if((e.code==="Enter" || e.code==="NumpadEnter")&& (this.state.loginModal.active || window.location.href.includes("loginMobile"))){
       if(this.state.loginModal.login){
        this.login(this.state.loginModal.username,this.state.loginModal.password)
       }else(
        this.handleRegister()
       )
      
   }
   }
   handleScroll=()=>{
    let newDimensions={...this.state.dimensions}
    newDimensions.scrollValue=window.scrollY
    this.setState({dimensions:newDimensions})
  }
  updateDimensions=()=>{
    const w = window;
    const d = document;
    const documentElement = d.documentElement;
    const body = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
    // if((height-this.state.dimensions.height<8 &&height-this.state.dimensions.height>0) || width-this.state.dimensions.width===){
    //   return
    // }
    if (width < 763){
      const newDimensions={width:width, height:height, isMobile:true}
      this.setState({dimensions:newDimensions})
      return
    }
    const newDimensions={width:width, height:height, isMobile:false}
    this.setState({dimensions:newDimensions})
  }
  updateVals=()=>{
    const val = this.state.dimensions.scrollValue
    const that = this

    try {
      // let navBar=document.querySelector('#root > div.mobileNav')

let mobileSearchBar=document.querySelector('#root > div:nth-child(2) > div.mobileSearchBar')
let bottomNavBar=document.querySelector('#root > div.bottomNavbar2')
     

      // if(window.location.href.includes('loginMobile')){
      //   navBar.style.display='none'
      // }else{
      //   navBar.style.display='flex'
      // }

      if(val<that.state.dimensions.height*0.85){
        mobileSearchBar.style.display="none !important"
        mobileSearchBar.style.position="absolute"
        mobileSearchBar.style.top="-81px"
        console.log("set")
        bottomNavBar.style.display="none !important"
        bottomNavBar.style.position="fixed"
        bottomNavBar.style.bottom="-81px"
        return
      }
      if(val>that.state.dimensions.height*0.85){
        mobileSearchBar.style.display="flex"
        bottomNavBar.style.display="grid"
        mobileSearchBar.style.position="fixed"
        mobileSearchBar.style.top="0px"
      }
      
      // if(val>30){
      //   const alertEl=document.querySelector('#root > div > div.mobileNav > div.alertBg')
      //   alertEl.style.top=-70+'px'
      //   const mobileSearchBar=document.querySelector('#root > div.mobileNav > div.mobileSearchBar')
      //   mobileSearchBar.style.top=0+'px'
      // }
    } catch (error) {
    }
    
  }
  constructor(props){
    super(props)
    this.routeProfileRef= React.createRef();

this.routeChatRef= React.createRef();
this.routeHomeRef= React.createRef();
this.routeListingsRef= React.createRef();
this.routeSavedRef= React.createRef();



  }
  componentDidUpdate(props){
    this.updateVals();
  }

  handleRegister = (data=false)=>{
    let neoModal = {...this.state.loginModal}
    neoModal.innerLoading=true
    this.setState({loginModal:neoModal})
    const that = this
    async function register (){
      let sendingData
      if(data){
        sendingData=data
      }else{
        sendingData=data={}
      }
      let myUsername = that.state.loginModal.username
      let myPassword = that.state.loginModal.password
      sendingData.username=myUsername
      sendingData.password=myPassword

      sendingData.phoneNumber=that.state.loginModal.phoneNumber
      let social = that.state.loginModal.social

      const isEmail = await validateEmail(myUsername)
      if(!isEmail){
        neoModal.innerLoading=false
        that.setState({loginModal:neoModal})
        return
      }
      if(myPassword.length<8){
        neoModal.innerLoading=false
        that.setState({loginModal:neoModal})
        return
      }
      if(social){
        axios.post(baseUrl+"custom-auth/",sendingData)
        .then(res=>{
          console.log(res.data, "logging you in 1 ...")
          that.systemLogin(res.data.user,res.data.token)
          neoModal.innerLoading=false
          neoModal.social=false
          neoModal.active=false
          that.setState({loginModal:neoModal})
          if(window.location.href.includes('/loginMobile')){
            // console.log("ta incluyendo")
            try{that.routeProfileRef.current.click()}catch (err){console.log(err)}
            return
        }else{
          // console.log("no ta incluyendo",window.location.href)
        }}
          ).catch(err=>{
            neoModal.innerLoading=false
            neoModal.innerError="error post info fetching"
            neoModal.social=false
            that.setState({loginModal:neoModal})
            console.log("hay bosbo")
            return
              })
      }else{
        sendingData.first_name=that.state.loginModal.firstName
        sendingData.last_name=that.state.loginModal.lastName
        await axios.post(baseUrl+"api/existing/",sendingData)
        .then(res=>{
          if(res.data.message==="existe"){
            that.login(sendingData.username,sendingData.password)
            return
                  }
                  else{
                    that.login(sendingData.username,sendingData.password)
                    return
                  }
          },err=>{
            
          neoModal.innerLoading=false
          neoModal.innerError="Este correo ya existe, intente iniciar sesión"
          that.setState({loginModal:neoModal})
        })
        
      }


      //window.location.href = ("https://carly.do")
    }
    register()
  }

  login = (username,password) =>{
    
    let neoModal = {...this.state.loginModal}
    neoModal.innerLoading=true
    neoModal.innerError=null
    this.setState({loginModal:neoModal})
    axios.post(baseUrl+"token-auth/",{username,password})
    .then(res=>{
      console.log("success")
        this.systemLogin(res.data.user,res.data.token)
        let neoModal = {...this.state.loginModal}
        neoModal.innerLoading=false
        neoModal.active=false
        this.setState({loginModal:neoModal})
        if(window.location.href.includes('/loginMobile')){
            // console.log("ta incluyendo")
            try{this.routeProfileRef.current.click()}catch (err){console.log(err)}
        }else{
          // console.log("no ta incluyendo",window.location.href)
        }
        return
    },
    error=>{
      
      console.log("hay bobooo")
      let neoModal = {...this.state.loginModal}
      neoModal.innerLoading=false
      neoModal.innerError="Usuario y/o contraseña incorrecta"
      this.setState({loginModal:neoModal})
        return false
})}
systemLogin=(user,token)=>{
    window.localStorage.setItem('token',token);
    window.localStorage.setItem('userId',user.id);
    // let notifications = [].concat(user.activeLeasing).concat(user.activeRenting)
    // //console.log("estas iban a ser las notfs", notifications)
    // const notificationClient = this.openNotifications(user.id)
    const newSwitch = {...this.state.loginModal}
    newSwitch.active=false
    console.log(user)
    this.setState({user,fetchedUser:true,logged:true,loginModal:newSwitch});

  //   if(window.location.href.includes('/loginMobile')){
  //     // console.log("ta incluyendo")
  //     try{this.routeHomeRef.current.click()}catch (err){console.log(err)}
  // }
    


}

selectStep=(step)=>{
  let currentStep = {...this.state.steps}
  currentStep.selectedStep=step
  this.setState({steps:currentStep});
}
logout = () =>{
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');

this.setState({fetchedUser:false,logged:false,user:null,selected:'home'})
try{this.routeHomeRef.current.click()}catch (err){console.log(err)}




}
deleteListing=(id)=>{
  this.virtualDeleteListing(id)

  this.state.myListing.listingPictures.forEach( picture => {
    axios.delete(picture.url)
  });
  const token = window.localStorage.getItem('token')
  axios.defaults.headers.delete['Authorization']="JWT "+token
  axios.delete(baseUrl + `api/listings/`+id).then(resp=>{
    //this.reFetchUser()
  })
  
}

getUser=()=>{
  const token = window.localStorage.getItem('token')
  let userRequest=async function(){return null}
  let neoAxios ={...axios}
  if(token){
    axios.defaults.headers.get['Authorization']="JWT "+token
    userRequest = axios.get(baseUrl + `current_user/`)
  }
  axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  // const statesRequest = axios.get(baseUrl +"getStates/")
  // const countriesRequest = axios.get(baseUrl +"getCountries/")
  const results = axios.post(baseUrl+"getResults/",{"locationCode":null})
  
  const featuresRequest = axios.get(baseUrl +"getFeatures/")
  
  neoAxios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
  delete neoAxios.defaults.headers.get['Authorization'];
  const currenciesReq = neoAxios.get("https://api.exchangerate-api.com/v4/latest/USD")
  Promise.all([userRequest,featuresRequest,results,currenciesReq,]).then(res => {
    const data = res[0].data;
    const features = res[1].data;
    const rawResults=res[2].data;
    const currenciesData=res[3].data;
    console.log(rawResults,"rawResults")
    console.log(currenciesData,"data currencies")
    let newSteps = {...this.state.steps}
    //newSteps.options.features=features
    //Notifications and Chat set up

    


    //
    //console.log("done!!",data)
    if(data){
      let newListings = []
      data.listings.forEach(listing => {
        // const rate = this.state.currency.rates[this.state.currency.selected]
        // listing.dailyPrice = listing.dailyPrice*rate
        // listing.weeklyPrice = listing.weeklyPrice*rate
        newListings.push(listing)
      }); 
      data.listings = newListings
    }

    // if(data.is_superuser){this.fetchSupportChats()}
    let switcher;
    let neoCurrency = {...this.state.currency}
    neoCurrency.rates=currenciesData.rates
    data?switcher=true:switcher=false
    this.setState({user:data},()=>{
      this.setState({rawResults,fetchedUser:switcher,logged:switcher,currency:neoCurrency,
        dialogFetching:false,steps:newSteps})
      
      /*
      const rates = res[1].data.rates
      let currency = {...this.state.currency}
      currency.rate = rates[currency.preferred]
      currency.rates = {"DOP":rates["DOP"],"EUR":rates["EUR"]}
      this.setState({currency:currency})
      */
    })
  }).catch(error=>{console.log("error getting user",error)})
}
fetchElements=()=>{
delete axios.defaults.headers.get['Authorization'];
axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
const featuresRequest = axios.get(baseUrl +"getFeatures/")
const statesRequest = axios.get(baseUrl +"getStates/")
const countriesRequest = axios.get(baseUrl +"getCountries/")
const myBooking =this.state.myBooking
let endDate
let startDate
if(myBooking.endDate===null || myBooking.length<9){
  endDate=moment().add(7,'d')
}else{
  endDate=moment(myBooking.endDate)
}
if(myBooking.startDate===null || myBooking.length<9){
  startDate=moment()
}else{
  startDate=moment(myBooking.startDate)
}
let counter=0

Promise.all([statesRequest,countriesRequest,featuresRequest]).then(res => {
  const features = res[3].data;
  const states = res[0].data;
  const countries = res[1].data;
  const results = res[2].data;

  //console.log("fetched data", states, countries,"resuts",results)
  this.setState({elements:{features:features,states:states,countries:countries}})
  this.setState({rawResults:results})
  counter=counter+1
  if(counter>1){
    counter=-1
    this.setState({fetched:true})
  }
})
axios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
axios.get("https://api.exchangerate-api.com/v4/latest/USD").then(
  resp=>{
    let currency={selected:"DOP",rates:resp.data.rates,symbols:currencySymbols}
    //console.log("respuesta covnersion monedas",currency)
    this.setState({currency:currency})
    counter=counter+1
    if(counter>1){
      counter=-1
      this.setState({fetched:true})
    }
    axios.defaults.proxy = null
  }
).catch(err=>{
  console.log("error con api monedas",err)
  console.log("using mock up data")
  let currency={selected:"DOP",symbols:currencySymbols,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}}
  this.setState({currency:currency})
  counter=counter+1
  if(counter>1){
    counter=-1
    this.setState({fetched:true})
  }
  axios.defaults.proxy = null
})
}

   componentDidMount(){
    this.position()
    this.getUser()
    this.updateDimensions()
    document.querySelector('html').addEventListener('keydown',this.processKey)
    window.addEventListener('scroll',this.handleScroll)
    window.addEventListener("resize", this.updateDimensions);
   }
   componentWillUnmount(){
    document.querySelector('html').removeEventListener('keydown')
    window.removeEventListener('scroll')
  }
   switchLoginModal=(newModal)=>{
     try {
      let navElement=document.querySelector('#root > div > div.navBar');
      if(newModal.active){
        
        navElement.style.position="relative"
      }else{
       navElement.style.position="fixed"
      }
     } catch (error) {
       //pass
     }
    
     this.setState({loginModal:newModal})
   }
   switchRent=(e,f=null)=>{
    let prevVal
    if(f){
      prevVal= true
    }else{
      if(!f){
        prevVal= false
      }else{
        prevVal= !this.state.rent
      }
      
    }
    this.setState({rent:prevVal})
   }
   position = async () => {
    const that = this
    await navigator.geolocation.getCurrentPosition(
      position => {
        console.log(position,"position")
        that.setState({
        latitude: position.coords.latitude, 
        longitude: position.coords.longitude
      })}, 
      err => {
        that.setState({ 
          latitude: 18.4637770,
          longitude: -69.9336969
        })
        console.log("error ubicando cliente",err)}
    );
    console.log("lattitude",this.state.latitude)
    
  }

  
  render() {
    const methods={login:this.login,logout:this.logout,formatNumber,neoSelected:this.neoSelected,handleRegister:this.handleRegister}
    const userPack={user:this.state.user,axiosPack:this.state.axiosPack,latitude:this.state.latitude,
      longitude:this.state.longitude, dimensions:this.state.dimensions,
      loginModal:this.state.loginModal,methods:methods,selected:this.state.selected,
      rent:this.state.rent,switchRent:this.switchRent,currency:this.state.currency,rawResults:this.state.rawResults,
      switchLoginModal:this.switchLoginModal}
    return ( 
      <>
      {this.state.loginModal.active?<LoginModal userPack={userPack}/>:null}

      <Link to="profile" className="buttonClass" ref={this.routeProfileRef}/>
      <Router>
        
         <MainView path="/" userPack={userPack} />
         <Listings path="/listings" userPack={userPack} />
         <LoginMobile path="/loginMobile" userPack={userPack} />
         <Profile path="/profile" userPack={userPack}/>
         <Chat  path="/chat" userPack={userPack}/>
      </Router>
     

      <Link to="chat" className="buttonClass" ref={this.routeChatRef}/>
      <Link to="saved" className="buttonClass" ref={this.routeSavedRef}/>
      <Link to="/" className="buttonClass" ref={this.routeHomeRef}/>
      <Link to="listings" className="buttonClass" ref={this.routeListingsRef}/>
      <BottomNavBar userPack={userPack}/>
  </>
     );
  }
}
 
export default App;
