import React from 'react';

export default function(props){
    const logout=()=>{
        props.userPack.methods.logout()
    }
    return(
        <>
        {props.userPack.user?
        <>
            <p className="mobileTitle">Hola, {props.userPack.user.first_name}</p>
                <a href="#" onClick={logout} className="">logout</a>

        </>:null}

        </>
    )
}