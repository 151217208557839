import React from 'react';
import {Link} from '@reach/router'
import ProfileButton from './ProfileButton';

export default function(props){
    const openLogin=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=true
        props.userPack.switchLoginModal(newVal)
      }
      const openRegister=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=false
        props.userPack.switchLoginModal(newVal)
      }
      const switchrent=(e)=>{ 
          console.log("activo aqui rent")
        props.userPack.switchRent("",true)
    }
    const switchsale=(e)=>{ 
        console.log("activo aqui")
      props.userPack.switchRent("",false)
  }
    return(
        <>
        <div className="mobileNavListings">
                    
                    {/* <div className="alertBg">
                        <p className="normal" style={{marginTop:"10px"}}>Obtén la información más reciente y relevante acerca del COVID-19. <a href="#" className="underLine" style={{color:"inherit"}}>Leer más</a></p></div> */}
                    <div className="mobileSearchBarSmall">
                        <div className="alignCenter justify">
                        <div className="innerMobileSearchSmall">
                           
                        <svg style={{marginLeft:"18px"}} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7" stroke="#37A000" strokeWidth="2"/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke="#37A000" strokeWidth="2"/>
                            </svg>
                            {/* <input className="mainInput" type="text" name="" id=""/> */}
                    
                            
                        </div>
                        <div className="currencyDiv">
                        <p className="selectedCurrency buttonClass">RD$</p>
                        <div className="currencyInnerDiv">
                            <p className="buttonClass">RD$</p>
                            <p className="buttonClass">US$</p>
                            <p className="buttonClass">EUR€</p>
                        </div>
                        </div>
                        </div>

                        <div className="alignCenter justify">
                        <div className="botones">
                <button className={props.userPack.rent?"offButton":"onButton"} onClick={switchsale}><p className="smallButtonText white centerX">Comprar</p></button><button className={!props.userPack.rent?"offButton":"onButton"} onClick={switchrent}><p className="smallButtonText white centerX">Alquilar</p></button>
            </div>  
                        </div>
                        
                    </div>
                   
                </div>
         <div className="navBar">
        <div className="leftNav">
            <Link to="../" style={{color:"inherit"}}>
            <img className=" centerX" src="media/mainLogo.svg" alt="" srcSet=""/>
        </Link>
        </div>
        <div className="centerNav">
            <div className="mainSearch centerY">
                <svg style={{marginLeft:"8px"}} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9694 14.1426C16.5025 13.719 16.0481 13.2832 15.6067 12.8357C15.2359 12.4788 15.0126 12.2191 15.0126 12.2191L12.2214 10.9566C13.3389 9.75603 13.9556 8.21056 13.9559 6.61001C13.9559 2.96601 10.8258 0 6.97796 0C3.13012 0 0 2.96601 0 6.61001C0 10.254 3.13012 13.22 6.97796 13.22C8.73541 13.22 10.3374 12.5968 11.5665 11.5779L12.8993 14.2219C12.8993 14.2219 13.1734 14.4334 13.5502 14.7847C13.936 15.1275 14.4434 15.5911 14.9299 16.0755L16.2836 17.39L16.8857 18L19 15.9972L18.356 15.4268C17.9782 15.0755 17.4738 14.6091 16.9694 14.1426ZM6.97796 11.3314C4.22964 11.3314 1.9937 9.21341 1.9937 6.61001C1.9937 4.00661 4.22964 1.88857 6.97796 1.88857C9.72629 1.88857 11.9622 4.00661 11.9622 6.61001C11.9622 9.21341 9.72629 11.3314 6.97796 11.3314Z" fill="#37A000"/>
                    </svg>
                    <input className="mainInput" type="text" name="searchText" id="searchText" placeholder="Búsqueda..."/>
                </div>
        </div>
        <div className="center2">
            <div className="botones centerY">
                <button className={props.userPack.rent?"offButton":"onButton"} onClick={switchsale}><p className="smallButtonText white centerX">Comprar</p></button><button className={!props.userPack.rent?"offButton":"onButton"} onClick={switchrent}><p className="smallButtonText white centerX">Alquilar</p></button>
            </div>  
            
        </div>

        <div className="rightNav alignCenter">
        {props.userPack.user?
          <div className="alignCenter"><ProfileButton methods={props.userPack.methods}/></div>
          :
          <>
                    <a onClick={openLogin} style={{color:"inherit"}} href="#" >
                    <p className="navBarTitle" style={{marginRight:"1.5rem"}}>Login</p>
                    </a>
                    <a href="#" onClick={openRegister} style={{color:"inherit"}} className="registerButton alignCenter justify">
                        <p className="registerText " >Regístrate</p>
                    </a>
                    </>
          }
         </div>  
        </div>
        </>
    )
}