import React from 'react';
import Card from './elements/Card';
import ListingsNavBar from './elements/ListingsNavBar';
import GoogleMapReact from 'google-map-react';

const AnyReactComponentOG = (props) => {
    return( 
      <>
         <p>test</p>
  
   </>
    )
  }



const AnyReactComponent = (props) => {
    return(
      <>
 <p>test</p>
   </>
    )
  }
export default function(props){

      const leche=props.userPack.rawResults.filter(c=>c.forSale===!props.userPack.rent)
    return(
        <>
        <ListingsNavBar userPack={props.userPack}/>

        <div style={{marginTop: "70px", marginLeft: "15px"}}>
    <p className="resultsText">Bienes Raíces {props.userPack.rent?"en alquiler":"en venta"} |  </p>
        <p className="normalText" style={{paddingTop:"4px"}}>{leche.length} {leche.length===1?"resultado":"resultados"}</p>
        </div>

        <div className="mainListingdiv" >
            <div className="cards justify" style={{mariginLeft:"1.5rem"}} >
                {leche.map(listing=>(
                    <Card key={listing.id} listing={listing} currency={props.userPack.currency}
                    format={props.userPack.methods.formatNumber}/>
                ))}
            
            </div>

            <div className="map">
                <p></p>
            </div>
        </div>
        <div className="alignCenter justify">
        <div className="floatingIcon">
            <div className="alignCenter justify" style={{borderRight:"1px solid black",paddingLeft:"5px",height:"83%"}}>
            <svg style={{position:"absolute",top:"0px"}} width="26" height="26" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.7023 7.88557L19.9523 4.01057C19.7728 3.92078 19.5749 3.87402 19.3743 3.87402C19.1736 3.87402 18.9757 3.92078 18.7963 4.01057L11.6249 7.59753L4.45229 4.01057C4.25532 3.91214 4.03645 3.86571 3.81648 3.87567C3.59651 3.88563 3.38273 3.95166 3.19546 4.06749C3.00819 4.18333 2.85364 4.34511 2.74649 4.53748C2.63934 4.72985 2.58315 4.94642 2.58325 5.16661V21.9583C2.58325 22.4478 2.85967 22.8947 3.29754 23.1143L11.0475 26.9893C11.227 27.0791 11.4249 27.1259 11.6256 27.1259C11.8262 27.1259 12.0241 27.0791 12.2036 26.9893L19.3749 23.4024L26.5475 26.988C26.744 27.0874 26.9627 27.1345 27.1826 27.1248C27.4025 27.115 27.6162 27.0488 27.803 26.9325C28.1841 26.6961 28.4166 26.2815 28.4166 25.8333V9.04162C28.4166 8.55207 28.1402 8.10516 27.7023 7.88557V7.88557ZM12.9166 9.83987L18.0833 7.25653V21.16L12.9166 23.7434V9.83987ZM5.16659 7.25653L10.3333 9.83987V23.7434L5.16659 21.16V7.25653ZM25.8333 23.7434L20.6666 21.16V7.25653L25.8333 9.83987V23.7434Z" fill="black"/>
</svg>

                <p style={{position:"absolute",bottom:"0px"}}>mapa</p>
            </div>
           
            <div className="alignCenter justify" style={{paddingRight:"5px"}}>
            <svg style={{position:"absolute",top:"2px"}} 
            width="22" height="22" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.625 2.375H2.25V5.74313L10.1231 13.6269L10.75 14.2431V23.625H15V21.5H17.125V23.625C17.125 24.1886 16.9011 24.7291 16.5026 25.1276C16.1041 25.5261 15.5636 25.75 15 25.75H10.75C10.1864 25.75 9.64591 25.5261 9.2474 25.1276C8.84888 24.7291 8.625 24.1886 8.625 23.625V15.125L0.751876 7.25188C0.553009 7.05419 0.395234 6.8191 0.287645 6.56015C0.180055 6.30121 0.124778 6.02353 0.125001 5.74313V2.375C0.125001 1.81141 0.348884 1.27091 0.747399 0.872398C1.14591 0.473883 1.68642 0.25 2.25 0.25H25.625V2.375Z" fill="black"/>
<path d="M29.5669 7.99556L26.3794 4.80806C26.2806 4.70847 26.1631 4.62943 26.0336 4.57549C25.9041 4.52155 25.7653 4.49377 25.625 4.49377C25.4847 4.49377 25.3459 4.52155 25.2164 4.57549C25.0869 4.62943 24.9694 4.70847 24.8706 4.80806L15 14.6893V19.3749H19.6856L29.5669 9.50431C29.6665 9.40554 29.7455 9.28802 29.7994 9.15855C29.8534 9.02907 29.8812 8.8902 29.8812 8.74994C29.8812 8.60967 29.8534 8.4708 29.7994 8.34132C29.7455 8.21185 29.6665 8.09433 29.5669 7.99556V7.99556ZM18.8144 17.2499H17.125V15.5606L22.4375 10.2481L24.1269 11.9374L18.8144 17.2499ZM25.625 10.4393L23.9356 8.74994L25.625 7.06056L27.3144 8.74994L25.625 10.4393Z" fill="black"/>
</svg>

                <p style={{position:"absolute",bottom:"0px"}}>filtros</p>
            </div>
    </div>
        </div>
        {props.userPack.dimensions.width<760?null:
        <div style={{width:"40vw",height:props.userPack.dimensions.height-60+"px",position:"fixed",right:"0px",top:"60px",zIndex:"1 !important"}}>
        <GoogleMapReact
            bootstrapURLKeys={{ key:"AIzaSyCqlkdTb3JXFdwIaMI_oLAE_mwnPRHiVNM&v=3.exp&libraries=geometry,drawing,places" }}
            // defaultCenter={{lat:props.userPack.latitude,lng:props.userPack.longitude}} 
            defaultCenter={{lat:18.4637770,lng:-69.9336969}}
            defaultZoom={13}
            onClick={(e)=>{console.log("clicked",e  )}}
          >
           {/* <AnyReactComponentOG
              lat={props.userPack.latitude}
              lng={props.userPack.longitude}
              text="My Marker"/> */}

            {/* {props.results.map(element=>(
                          <AnyReactComponent key={element.id}
                          lat={element.owner.deliveryAddressList[0].xCoordinate}
                          lng={element.owner.deliveryAddressList[0].yCoordinate}
                          element={element}
                          text="My Marker"
                        />
            ))} */}
          </GoogleMapReact>
          </div>
          }
        
        </>
    )
}